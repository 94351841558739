import PropTypes from 'prop-types';
import { GridActionsCellItem, itIT } from '@mui/x-data-grid';
import { StripedDataGrid } from '../../components/DataGrid/StripedDataGrid';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export const GoalTypesDataGrid = ({
                                      data,
                                      onEditClick,
                                      onDeleteClick,
                                  }) => {

    const { t } = useTranslation();
    const columns = useMemo(() => [
        { field: 'name', headerName: 'Obiettivo', flex: 1.5 },
        { field: 'category', headerName: 'Categoria', flex: 1.5 },
        { field: 'easy', headerName: 'Facile', flex: 1.5 },
        { field: 'hard', headerName: 'Difficile', flex: 1.5 },
        {
            field: 'actions',
            headerName: 'Azioni',
            type: 'actions',
            cellClassName: 'actions',
            getActions: ({ id }) => [
                <GridActionsCellItem
                    icon={<EditOutlinedIcon/>}
                    label="Edit"
                    onClick={() => onEditClick(id)}
                    color="inherit"
                    key={`edit_${id}`}
                />,
                <GridActionsCellItem
                    icon={<DeleteOutlinedIcon/>}
                    label="Delete"
                    onClick={() => onDeleteClick(id)}
                    color="inherit"
                    key={`delete_${id}`}
                />,
            ]
        }
    ], [onEditClick, onDeleteClick]);
    const rows = useMemo(() => JSON.parse(JSON.stringify(data)).map(row => ({
        ...row,
        category: t(`retrospective.goalTypeCategory.${row.category}`),
    })), [t, data]);


    return (
        <StripedDataGrid
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={rows}
            columns={columns}
            density='standard'
            disableColumnMenu
            disableRowSelectionOnClick
            getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            getRowHeight={() => 'auto'}
            initialState={{
                sorting: {
                    sortModel: [
                        { field: 'name', sort: 'asc' }
                    ],
                },
                pagination: {
                    paginationModel: {
                        pageSize: 100,
                    },
                },
            }}
            pageSizeOptions={[25, 50, 100]}
            sx={{
                '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
            }}
        />
    );
};

GoalTypesDataGrid.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
};

GoalTypesDataGrid.defaultProps = {
    data: [],
    onEditClick: () => {
    },
    onDeleteClick: () => {
    },
};
