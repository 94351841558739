import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import I3Modal from '../../components/Layout/I3Modal';
import { PlusCircle } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import { renderSpinner } from '../../utils/Utility';
import I3ModalConfirmation from '../../components/Layout/I3ModalConfirmation';
import { PERMISSIONS } from '../../constants';
import { Unauthorized } from '../../components/Unauthorized';
import { GoalTypesDataGrid } from "./GoalTypesDataGrid";
import { deleteGoalType, getGoalTypes } from "../../utils/API/goalTypes";
import { GoalTypeForm } from "./GoalTypeForm";

export const GoalTypesPage = () => {
    const { hasClaim } = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false);
    const [showUpsertModal, setShowUpsertModal] = useState(false);
    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
    const [selectedGoalType, setSelectedGoalType] = useState(undefined);
    const [goalTypes, setGoalTypes] = useState([]);

    if (!hasClaim(PERMISSIONS.RETROSPECTIVE_ADMINISTRATION)) {
        return (<Unauthorized/>);
    }

    useEffect(() => {
        setIsLoading(true);
        const getData = async () => {
            setGoalTypes(await getGoalTypes());
        }
        getData().finally(() => setIsLoading(false));
    }, []);

    const handleAddClick = () => {
        setSelectedGoalType(undefined);
        setShowUpsertModal(true);
    };

    const handleEditClick = (id) => {
        const goalType = goalTypes.find(i => i.id === id);
        setSelectedGoalType(goalType);
        setShowUpsertModal(true);
    };

    const handleDeleteClick = (id) => {
        const goalType = goalTypes.find(i => i.id === id);
        setSelectedGoalType(goalType);
        setShowDeleteConfirmationModal(true);
    };

    const handleOnDataChanged = (item) => {
        const goalType = goalTypes.find(i => i.id === item.id);
        if (!goalType) {
            setGoalTypes([...goalTypes, item]);
        } else {
            setGoalTypes(goalTypes.map(i => i.id === item.id ? item : i));
        }
    }

    const handleConfirmDeleteClick = async () => {
        try {
            await deleteGoalType(selectedGoalType.id);
            setGoalTypes(goalTypes.filter(i => i.id !== selectedGoalType.id));
        } finally {
            setShowDeleteConfirmationModal(false);
        }
    };

    return (<>
        <div className="content__wrapper">
            <div className="headerPage content">
                <div className="title">Tipi di obiettivi</div>
                <div className="spacer"/>
                <div className="buttonGroup">
                    <Button variant="primary" onClick={() => handleAddClick()}>
                        <PlusCircle/>
                        Inserisci
                    </Button>
                </div>
            </div>
            <div className="content__fixed_height">
                <div className="tabsContainer" style={{ flex: '1 1 0px' }}>
                    {isLoading
                        ? (renderSpinner(true))
                        : (<GoalTypesDataGrid
                            data={goalTypes}
                            onEditClick={handleEditClick}
                            onDeleteClick={handleDeleteClick}
                        />)
                    }
                </div>
            </div>
        </div>
        <I3Modal
            show={showUpsertModal}
            onHide={() => setShowUpsertModal(false)}
            title={selectedGoalType ? "Modifica tipo di obiettivo" : "Inserisci tipo di obiettivo"}
        >
            <GoalTypeForm
                item={selectedGoalType}
                onDataChanged={handleOnDataChanged}
                onClose={() => setShowUpsertModal(false)}
            />
        </I3Modal>
        <I3ModalConfirmation
            show={showDeleteConfirmationModal}
            onHide={() => setShowDeleteConfirmationModal(false)}
            title="Cancella tipo di obiettivo"
            questionTitle="Vuoi cancellare questo tipo di obiettivo?"
            onConfirm={handleConfirmDeleteClick}
        />
    </>);
};
