/* eslint-disable react/prop-types */
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { Form as FormikForm, Formik } from 'formik';
import FormCheckbox from '../../components/Form/FormCheckbox';
import FormItem from '../../components/Form/FormItem';
import { createGoalType, updateGoalType } from "../../utils/API/goalTypes";
import FormSelect from "../../components/Form/FormSelect";
import { useTranslation } from 'react-i18next';

const initialValues = (currentData) => ({
    id: currentData?.id || '',
    name: currentData?.name || '',
    category: currentData?.category || '',
    easy: currentData?.easy || '',
    hard: currentData?.hard || '',
});

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Campo obbligatorio').max(255, 'Lunghezza massima 250 caratteri'),
    category: Yup.string().required('Campo obbligatorio').max(50, 'Lunghezza massima 50 caratteri'),
    easy: Yup.string(),
    hard: Yup.string(),
});

export const GoalTypeForm = ({
                                 item,
                                 onDataChanged,
                                 onClose,
                             }) => {
    const { t } = useTranslation();
    const isNewItem = item?.id === undefined;

    const getCategoryOptions = () => [
        "academy",
        "wellness",
        "certification",
        "skills",
        "conference",
        "guild",
        "languages",
        "events",
        "people_and_culture",
        "communication",
        "project",
        "role",
        "public_speaking",
        "team",
        "university",
        "company",
    ].map((category) =>
        <option key={category} value={category}>
            {t(`retrospective.goalTypeCategory.${category}`)}
        </option>
    );

    const onSubmit = async (values, actions) => {
        const { resetForm, setSubmitting } = actions;
        const { id, name, category, easy, hard, createAnother } = values;
        const payload = { name, category, easy, hard };

        let data;
        try {
            if (id) {
                data = await updateGoalType(id, payload);
            } else {
                data = await createGoalType(payload);
            }
            onDataChanged(data);
            if (!createAnother) {
                onClose();
            } else {
                resetForm();
            }
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Formik
            initialValues={initialValues(item)}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  isValidating,
              }) => (
                <FormikForm noValidate onSubmit={handleSubmit}>
                    <FormItem
                        type="text"
                        name="name"
                        label="Nome"
                        maxLength="250"
                        className="form-group"
                    />
                    <FormSelect
                        name="category"
                        label="Categoria"
                        options={getCategoryOptions()}
                    />
                    <FormItem
                        type="textarea"
                        name="easy"
                        label="Facile"
                        className="form-group"
                    />
                    <FormItem
                        type="textarea"
                        name="hard"
                        label="Difficile"
                        className="form-group"
                    />
                    <div className="buttonContent">
                        {isNewItem && (
                            <FormCheckbox
                                name="createAnother"
                                label="Aggiungi un altro"
                            />
                        )}
                        <div className="spacer"/>
                        <Button
                            onClick={onClose}
                            variant='secondary'
                        >
                            Annulla
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={isSubmitting || isValidating || !isValid}
                        >
                            {isNewItem ? 'Inserisci' : 'Aggiorna'}
                        </Button>
                    </div>
                </FormikForm>
            )}
        </Formik>
    );
};
