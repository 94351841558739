import { API } from '../../constants';
import request from '../axios';
import { error, success } from '../notification';

export const getGoalTypes = async () => {
    try {
        const { data } = await request.get(API.GOAL_TYPES);
        return data;
    } catch (e) {
        error('Errore', 'Caricamento fallito');
        throw e;
    }
}

export const createGoalType = async (data) => {
    try {
        const res = await request.post(API.GOAL_TYPES, data);
        success('Esito', 'Inserimento riuscito');
        return res.data;
    } catch (e) {
        error('Errore', 'Inserimento fallito');
        throw e;
    }
}

export const updateGoalType = async (id, data) => {
    try {
        const res = await request.put(`${API.GOAL_TYPES}${id}/`, data);
        success('Esito', 'Aggiornamento riuscito');
        return res.data;
    } catch (e) {
        error('Errore', 'Aggiornamento fallito');
        throw e;
    }
}

export const deleteGoalType = async (id) => {
    try {
        await request.delete(`${API.GOAL_TYPES}${id}/`);
        success('Esito', 'Cancellazione avvenuta con successo');
    } catch (e) {
        error('Errore', 'Cancellazione fallita');
        throw e;
    }
}